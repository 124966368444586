import ConditionBuilder from "@/includes/logic/Modules/Builders/ConditionBuilder";
import UserTypeCondition from "./UserTypeCondition";
import { ModuleConditionTypeEnum } from "../../../types/types";
import ConfigCondition from "@/includes/logic/Modules/models/ConfigCondition";

import { VueComponent } from "piramis-base-components/src/shared/types/VueComponent";

class UserTypeConditionBuilder extends ConditionBuilder {
  type: ModuleConditionTypeEnum = ModuleConditionTypeEnum.UserType;

  icon: string = 'workspace_premium';

  view: VueComponent = () => import('../../../../../../components/Modules/conditions/UserTypeCondition/UserTypeConditionView.vue');
  preview: VueComponent = () => import('../../../../../../components/Modules/conditions/UserTypeCondition/UserTypeConditionValue.vue');

  getModel(): ConfigCondition {
    return new UserTypeCondition();
  }
}

export default new UserTypeConditionBuilder()
