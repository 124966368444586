import ConditionBuilder from "../../../Builders/ConditionBuilder";
import ResolveFromApCondition
  from "@/includes/logic/Modules/models/conditions/ResolveFromApCondition/ResolveFromApCondition";
import ConfigCondition from "@/includes/logic/Modules/models/ConfigCondition";
import { ModuleConditionTypeEnum } from "../../../types/types";

class ResolveFromApConditionBuilder extends ConditionBuilder {
  type: ModuleConditionTypeEnum = ModuleConditionTypeEnum.ResolveFromAp;

  icon = 'exposure';

  view:ConditionBuilder['view'] = () => import('@/components/Modules/conditions/ResolveFromCondition/ResolveFromConditionView.vue');
  preview:ConditionBuilder['view'] = () => import('@/components/Modules/conditions/ResolveFromCondition/ResolveFromConditionValue.vue');

  getModel(): ConfigCondition {
    return new ResolveFromApCondition()
  }
}

export default new ResolveFromApConditionBuilder()
