import ConditionBuilder from "@/includes/logic/Modules/Builders/ConditionBuilder";
import BoostsCondition from "./BoostsCondition";
import ConfigCondition from "@/includes/logic/Modules/models/ConfigCondition";
import { ModuleConditionTypeEnum } from "@/includes/logic/Modules/types/types";

import { VueComponent } from "piramis-base-components/src/shared/types/VueComponent";

class BoostsConditionBuilder extends ConditionBuilder {
  type: ModuleConditionTypeEnum = ModuleConditionTypeEnum.Boosts;

  icon: string = 'volunteer_activism';
  view: VueComponent = () => import('../../../../../../components/Modules/conditions/BoostsCondition/BoostsConditionView.vue');
  preview: VueComponent = () => import('../../../../../../components/Modules/conditions/BoostsCondition/BoostsConditionValue.vue');

  getModel(): ConfigCondition {
    return new BoostsCondition()
  }
}

export default new BoostsConditionBuilder()
