import ConditionBuilder from "../../../Builders/ConditionBuilder";
import UsersCondition from "./UsersCondition";
import ConfigCondition from "@/includes/logic/Modules/models/ConfigCondition";
import { ModuleConditionTypeEnum } from "@/includes/logic/Modules/types/types";

class UsersConditionBuilder extends ConditionBuilder {
  type: ModuleConditionTypeEnum = ModuleConditionTypeEnum.Users;

  icon = 'group';

  view:ConditionBuilder['view'] = () => import('@/components/Modules/conditions/UsersCondition/UsersConditionView.vue');
  preview:ConditionBuilder['view'] = () => import('@/components/Modules/conditions/UsersCondition/UsersConditionValue.vue');

  getModel(): ConfigCondition {
    return new UsersCondition();
  }

}

export default new UsersConditionBuilder()
