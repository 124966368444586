import ConditionBuilder from "../../../Builders/ConditionBuilder";
import ResolveFromRepCondition from "./ResolveFromRepCondition";
import ConfigCondition from "@/includes/logic/Modules/models/ConfigCondition";
import { ModuleConditionTypeEnum } from "../../../types/types";

class ResolveFromRepConditionBuilder extends ConditionBuilder {
  type: ModuleConditionTypeEnum = ModuleConditionTypeEnum.ResolveFromRep

  icon = 'thumb_up';

  view:ConditionBuilder['view'] = () => import('@/components/Modules/conditions/ResolveFromCondition/ResolveFromConditionView.vue');
  preview:ConditionBuilder['view'] = () => import('@/components/Modules/conditions/ResolveFromCondition/ResolveFromConditionValue.vue');

  getModel(): ConfigCondition {
    return new ResolveFromRepCondition()
  }
}

export default new ResolveFromRepConditionBuilder()
