import ConditionBuilder from "../../../Builders/ConditionBuilder";
import ResolveFromXpCondition from "./ResolveFromXpCondition";
import ConfigCondition from "@/includes/logic/Modules/models/ConfigCondition";
import { ModuleConditionTypeEnum } from "../../../types/types";

class ResolveFromXpConditionBuilder extends ConditionBuilder {
  type: ModuleConditionTypeEnum = ModuleConditionTypeEnum.ResolveFromXp;

  icon = 'exposure';

  view:ConditionBuilder['view'] = () => import('@/components/Modules/conditions/ResolveFromCondition/ResolveFromConditionView.vue');
  preview:ConditionBuilder['view'] = () => import('@/components/Modules/conditions/ResolveFromCondition/ResolveFromConditionValue.vue');

  getModel(): ConfigCondition {
    return new ResolveFromXpCondition();
  }

}

export default new ResolveFromXpConditionBuilder()
