import ConfigCondition from "@/includes/logic/Modules/models/ConfigCondition";
import { ModuleConditionTypeEnum } from "@/includes/logic/Modules/types/types";

import { VueComponent } from "piramis-base-components/src/shared/types/VueComponent";

import { Exclude } from "class-transformer";
import { snakeCase } from "lodash";

export default abstract class ConditionBuilder {
  abstract type: ModuleConditionTypeEnum

  @Exclude()
  abstract view: VueComponent
  @Exclude()
  abstract preview: VueComponent

  /**
   * material icon
   **/
  @Exclude()
  abstract icon: string

  @Exclude()
  get snakeCaseType() {
    return snakeCase(this.type)
  }

  @Exclude()
  get titleKey() {
    return `module_condition_${ this.snakeCaseType }_title`
  }

  @Exclude()
  get helpMessageKey() {
    return `module_condition_${ this.snakeCaseType }_help_message`
  }
  isDisabled() {
    return false
  }

  abstract getModel(): ConfigCondition
}
