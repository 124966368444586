import ConditionBuilder from "../../../Builders/ConditionBuilder";
import ResolveFromRankCondition
  from "@/includes/logic/Modules/models/conditions/ResolveFromRankCondition/ResolveFromRankCondition";
import store from "@/store/store";
import ConfigCondition from "@/includes/logic/Modules/models/ConfigCondition";
import { ModuleConditionTypeEnum } from "@/includes/logic/Modules/types/types";

class ResolveFromRankConditionBuilder extends ConditionBuilder {
  type: ModuleConditionTypeEnum = ModuleConditionTypeEnum.ResolveFromRank;

  icon = 'military_tech';

  view:ConditionBuilder['view'] = () => import('@/components/Modules/conditions/ResolveFromCondition/ResolveFromConditionView.vue');
  preview:ConditionBuilder['view'] = () => import('@/components/Modules/conditions/ResolveFromCondition/ResolveFromConditionValue.vue');

  isDisabled() {
    return store.getters.isChatAtLeastAdvanced && !store.getters.isChatLicenseExists
  }

  getModel(): ConfigCondition {
    return new ResolveFromRankCondition();
  }
}

export default new ResolveFromRankConditionBuilder()
