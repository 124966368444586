import ConditionBuilder from "../../../Builders/ConditionBuilder";
import ZoneCondition from "./ZoneCondition";
import { ModuleConditionTypeEnum } from "@/includes/logic/Modules/types/types";
import ConfigCondition from "@/includes/logic/Modules/models/ConfigCondition";

class ZoneConditionBuilder extends ConditionBuilder {
  type: ModuleConditionTypeEnum = ModuleConditionTypeEnum.Zone;

  icon = 'animation';

  view:ConditionBuilder['view'] = () => import('@/components/Modules/conditions/ZoneCondition/ZoneConditionView.vue');
  preview:ConditionBuilder['view'] = () => import('@/components/Modules/conditions/ZoneCondition/ZoneConditionValue.vue');

  getModel(): ConfigCondition {
    return new ZoneCondition();
  }
}

export default new ZoneConditionBuilder()
