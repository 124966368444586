import ConditionBuilder from "../../../Builders/ConditionBuilder";
import WorkTimeCondition from "./WorkTimeCondition";
import { ModuleConditionTypeEnum } from "@/includes/logic/Modules/types/types";
import ConfigCondition from "@/includes/logic/Modules/models/ConfigCondition";

class WorkTimeConditionBuilder extends ConditionBuilder {
  type: ModuleConditionTypeEnum = ModuleConditionTypeEnum.WorkTime;

  icon = 'access_time';

  view:ConditionBuilder['view'] = () => import('@/components/Modules/conditions/WorkTimeCondition/WorkTimeConditionView.vue');
  preview:ConditionBuilder['view'] = () => import('@/components/Modules/conditions/WorkTimeCondition/WorkTimeConditionValue.vue');

  getModel(): ConfigCondition {
    return new WorkTimeCondition();
  }
}

export default new WorkTimeConditionBuilder()
