import ConditionBuilder from "../../../Builders/ConditionBuilder";
import TopicsCondition from "./TopicsCondition";
import ConfigCondition from "@/includes/logic/Modules/models/ConfigCondition";
import { ModuleConditionTypeEnum } from "@/includes/logic/Modules/types/types";

class TopicsConditionBuilder extends ConditionBuilder {
  type: ModuleConditionTypeEnum = ModuleConditionTypeEnum.Topics;

  icon = 'forum';

  view:ConditionBuilder['view'] = () => import('@/components/Modules/conditions/TopicsCondition/TopicsConditionView.vue');
  preview:ConditionBuilder['view'] = () => import('@/components/Modules/conditions/TopicsCondition/TopicsConditionValue.vue');

  getModel(): ConfigCondition {
    return new TopicsCondition()
  }
}

export default new TopicsConditionBuilder()
